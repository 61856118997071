import React from "react";

import { Button, Container, Popup } from "../../../../../../"

import css from "./style.module.scss"


export default (props) => {
    return <Popup

        title={
            <Container effects={[1]} className={`${css.picwellContainer}`}>
                Bio
            </Container>
        } visible={props.visible} onClose={props.onClose}>

        <Container className={css.contentContainer}>
            I was born and raised in the Kansas City area for the majority of my childhood. I didn't want to stray too far from home for college and that led me to Baker University in Baldwin City, Kansas, where I earned degrees in Business Management and Sports Administration, played on the Men's golf team, and was in the Delta Tau Delta fraternity.

            Upon graduation, I lived in Kolkata, India for almost three years working for a global sourcing company. Living and working in a foreign culture grew me in many areas and developed me professionally, emotionally, spiritually, and physically. It also broadened my perspective of our world and taught me that every person in this world is unique.

            My amazing wife, Allie, and I love living in the KC area. When I am not at work you could probably find me on the golf course, watching the golf channel (yes, I like golf), or hanging out with my wife or friends!

            When it comes to health insurance, I know that it can be frustrating, confusing, infuriating, and so many other things. But my hope and prayer is that I am able to help each and every person find the right plan for THEIR needs.
        </Container>
    </Popup>
}